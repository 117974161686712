import { ReactNode, useEffect } from "react";
import { LoadingProvider } from "./loading";
// import NotifyProvider from "./notify";
import { CommonDataProvider } from "./common-data";
import { ScrollToTop } from "components";
import NotifyProvider from "./notify";
import { SEARCH_PARAMS } from "constants/routes";
import { getCookie, setCookie } from "helpers/cookie";
import ChatProvider from "./chat";
import { FloatingActionBar } from "components/bar";

const AppProvider = ({ children }: { children: ReactNode }) => {
  const getUtmSource = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const utmSource = searchParams.get(SEARCH_PARAMS.utmSource);
    const localUtmSource = getCookie(SEARCH_PARAMS.utmSource);
    if (!localUtmSource) {
      setCookie(SEARCH_PARAMS.utmSource, utmSource || "origin", 30);
    }
  };

  useEffect(() => {
    getUtmSource();
  }, []);

  return (
    <CommonDataProvider>
      <ChatProvider>
        <LoadingProvider>
          <ScrollToTop />
          <NotifyProvider>
            {children}

            {/* Float action bar */}
            <FloatingActionBar />
          </NotifyProvider>
        </LoadingProvider>
      </ChatProvider>
    </CommonDataProvider>
  );
};

export default AppProvider;
