import { Stack, styled } from "@mui/material";
import { CloseIcon, UnLockIcon } from "assets/icons";
import { GreenButton } from "components/button";
import { TextButton } from "components/Button";
import GrayButton from "components/button/GrayButton";
import Row from "components/Row";
import { AttentionText } from "components/text";
import { SmText, StyledATag, TextMd, XsTextMd } from "components/Text";
import { COLORS } from "constants/layout";
import { PATHS } from "constants/routes";
import dayjs from "dayjs";
import { useTranslation } from "hooks/useTranslation";
import useUser from "hooks/useUser";
import { useLoading } from "providers/loading";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { apiPurchaseContact } from "services/api/contact";
import { apiGetUserBalance } from "services/api/user";
import { updateUserContactQuote } from "services/redux/actions/user";
import { BuyerInfosType } from "types/buyer";

const ContactUnlockPopup = (props: {
  onReload?: () => void;
  onClose?: () => void;
  contact_id?: string;
  title?: string;
  buyer?: BuyerInfosType;
  onClick?: () => void;
}) => {
  const t = useTranslation();
  const {
    onClose,
    contact_id,
    onReload,
    title = "contact_unlock_title",
    buyer,
    onClick,
  } = props;
  const { userContactQuote, user } = useUser();
  const { setLoading } = useLoading();
  const dispatch = useDispatch();

  const onClickGetContact = async () => {
    if (onClick) {
      onClick();
    } else {
      try {
        setLoading(true);
        await apiPurchaseContact({ contact_id });
        await apiGetUserBalance().then((res) => {
          dispatch(
            updateUserContactQuote(res.data.message?.data?.contact_quota)
          );
        });
        onReload?.();
        onClose?.();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const buyerIsInactive = useMemo(
    () =>
      !buyer?.last_active ||
      Math.abs(dayjs().diff(dayjs(buyer?.last_active), "day")) > 30,
    [buyer?.last_active]
  );

  const buyerNotVerified = useMemo(
    () =>
      !(buyer?.is_biz_verified === 1 || buyer?.is_verified_work_email === 1),
    [buyer]
  );

  return (
    <StyledUnlockPoup spacing={1}>
      <Row spacing={1}>
        <UnLockIcon width={24} className="lock-icon" />
        <TextMd>{t(title)}</TextMd>
        <div className="tmp" />
      </Row>
      <CloseIcon className="close-icon" onClick={onClose} />

      {userContactQuote === 0 && (
        <SmText textAlign="center" color={COLORS.error[600]}>
          {t("running_out_credits")}
        </SmText>
      )}

      <Row justifyContent="center">
        {userContactQuote > 0 ? (
          <GreenButton onClick={onClickGetContact} sx={{ gap: 1 }}>
            {t("get_buyer_contact")}
            <XsTextMd className="chip w-quote" color={COLORS.green[600]}>
              - 1 {t("credit")} / {userContactQuote}{" "}
              {t(userContactQuote > 1 ? "credits_left" : "credit_left")}
            </XsTextMd>
          </GreenButton>
        ) : (
          <StyledATag href={PATHS.pricing}>
            <GrayButton>{t("buy_credits_now")}</GrayButton>
          </StyledATag>
        )}
        {(user?.is_biz_verified === 0 || user?.is_verified_work_email === 0) &&
          userContactQuote === 0 && (
            <StyledATag href={PATHS.membershipFull}>
              <TextButton
                sx={{ color: COLORS.blue[700], fontWeight: 400, fontSize: 14 }}
              >
                {t("earn_free_credits")}
              </TextButton>
            </StyledATag>
          )}
      </Row>

      {(buyerIsInactive || buyerNotVerified) && (
        <AttentionText
          text={
            buyerIsInactive
              ? "attention_buyer_inactive"
              : buyerNotVerified
              ? "attention_buyer_not_verified"
              : undefined
          }
        />
      )}
    </StyledUnlockPoup>
  );
};

const StyledUnlockPoup = styled(Stack)(({ theme }) => ({
  padding: 16,
  position: "relative",
  ".lock-icon": {
    minWidth: 24,
  },
  ".close-icon": {
    width: 20,
    position: "absolute",
    top: 4,
    right: 12,
    cursor: "pointer",
  },
  ".tmp": {
    width: 12,
  },
  ".w-quote": {},
  [theme.breakpoints.down("md")]: {
    ".back-btn, .supp-price-btn": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
}));

export default ContactUnlockPopup;
