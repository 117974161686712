import { Stack } from "@mui/material";
import { Img } from "../Img";
import { forwardRef, useMemo } from "react";
import { Link, StyledRouterA, XsText, XsTextMd } from "../Text";
import { useIntl } from "react-intl";
import { followRel, formatImg } from "helpers/format";
import ListingChip from "../chips/ListingChip";
import { COLORS } from "constants/layout";
import { DIRECT_PATHS } from "constants/routes";
import { BuyerType } from "types/buyer";
import StyledBuyer from "./Buye.styles";
import RFQ from "./RFQ";
import useBreakpoints from "hooks/useBreakpoint";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import BaseBuyerInfors from "components/infos/BaseBuyerInfors";

type Props = {
  company?: BuyerType;
  fullLink?: boolean;
};

const Buyer = forwardRef(({ company, fullLink }: Props, ref: any) => {
  const intl = useIntl();
  const { isMobile } = useBreakpoints();

  const {
    slug,
    num_posted_rfq = 0,
    business_types,
    is_seen,
    activity_tags = [],
    no_follow = 0,
    import_countries,
    categories = [],
    rfqs = [],
    gallery_preview = [],
    payment_term,
    trade_term,
    num_employees,
    annual_revenue_amount,
    year_established,
    destination_port,
    is_biz_verified,
    is_verified_work_email,
  } = company || {};

  const formattedRfqs = useMemo(
    () => rfqs.slice(0, gallery_preview.length > 0 ? 1 : 2),
    [rfqs, gallery_preview]
  );

  const infors = useMemo(
    () =>
      [
        { label: "business_types", value: business_types },
        { label: "import_countries", value: import_countries },
        { label: "number_of_employees", value: num_employees },
        { label: "annual_revenue_amount", value: annual_revenue_amount },
        { label: "year_established", value: year_established },
        { label: "prefer_payment_term", value: payment_term },
        { label: "prefer_trade_term", value: trade_term },
        { label: "prefer_desctination_term", value: destination_port },
      ].filter((info) => info.value),
    [company]
  );

  return (
    <StyledBuyer ref={ref} className={is_seen ? "is-seen-box" : ""}>
      <Stack className="info-box" spacing={1}>
        <BaseBuyerInfors company={company} fullLink />
        {num_posted_rfq > 0 && (
          <XsTextMd className="status-label orange-label">
            {intl.formatMessage(
              { id: `qlt_rfq${num_posted_rfq > 1 ? "s" : ""}_posted` },
              { quantity: num_posted_rfq }
            )}
          </XsTextMd>
        )}

        {/* Text infos stack */}
        <Stack spacing={0.5}>
          {categories.length > 0 && (
            <XsText
              color={COLORS.green[600]}
              className="categories-text cutoff-text twoline"
              component="h3"
            >
              {intl.formatMessage({ id: "buyer_of" })}:{" "}
              {categories.map((category, i) => (
                <StyledRouterA
                  to={DIRECT_PATHS.categoryBuyer(category.name, fullLink)}
                  key={i}
                >
                  {category.category_name}
                  {i === categories.length - 1 ? "" : ", "}
                </StyledRouterA>
              ))}
            </XsText>
          )}

          <Stack direction="row" flexWrap="wrap" gap={1}>
            {infors.map(({ value, label }, i) => (
              <XsText className="info-text ellipsis-text" key={i}>
                {intl.formatMessage({ id: label }) + ": "} <span>{value}</span>
              </XsText>
            ))}
          </Stack>
        </Stack>

        {/* Buyer activity tags */}
        <ListingChip tags={activity_tags} />

        {isMobile && gallery_preview.length > 0 && (
          <div className="scroll-box medias">
            {gallery_preview.map((media, i) => (
              <Img src={formatImg(media, 3)} alt="media" key={i} />
            ))}
          </div>
        )}
      </Stack>

      {/* RFQ list and gallery */}
      {!isMobile && (
        <Stack direction="row" gap={1} className="right-box">
          {formattedRfqs.length > 0 ? (
            formattedRfqs.map((rfq, i) => <RFQ rfq={rfq} key={i} />)
          ) : (
            <div className="rfq" />
          )}

          {gallery_preview.length > 0 && (
            <Link to={`/${slug}`} rel={followRel(no_follow)} className="media">
              <Swiper
                className="medias"
                modules={[Pagination]}
                pagination={{
                  clickable: true,
                }}
              >
                {gallery_preview.map((media, i) => (
                  <SwiperSlide key={i}>
                    <Img
                      src={formatImg(media, 3)}
                      alt="media"
                      height="100%"
                      width="100%"
                      style={{ objectFit: "cover" }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Link>
          )}
        </Stack>
      )}
    </StyledBuyer>
  );
});

export default Buyer;
