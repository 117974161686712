import { styled } from "@mui/material";

interface StyledAProps {
  hideUnderline?: boolean;
  openNewTab?: boolean;
}

const StyledA = styled("a")<StyledAProps>(({ hideUnderline }) => ({
  textDecoration: "none",
  color: "#000",
  "&:hover": {
    textDecoration: hideUnderline ? "none" : "underline",
  },
}));

const StyledALink: React.FC<React.ComponentProps<"a"> & StyledAProps> = ({
  children,
  openNewTab,
  ...props
}) => (
  <StyledA
    {...props}
    target={openNewTab ? "_blank" : undefined}
    rel={openNewTab ? "noopener noreferrer" : undefined}
  >
    {children}
  </StyledA>
);

export default StyledALink;
