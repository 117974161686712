import {
  SelectProps,
  styled,
  Select as MuiSelect,
  MenuItem,
  Stack,
} from "@mui/material";
import { DownChevronIcon } from "assets/icons/chevron.icon";
import { SmText } from "components/Text";
import { forwardRef, useMemo } from "react";
import { useIntl } from "react-intl";
import { OptionType } from "types/common";

const Select = forwardRef(
  (
    {
      options = [],
      toplabel,
      requiredlabel,
      placeholder,
      children,
      value,
      ...rest
    }: SelectProps & {
      options?: OptionType[];
      toplabel?: string;
      requiredlabel?: boolean;
      placeholder?: string;
    },
    ref: any
  ) => {
    const intl = useIntl();
    const defaultPlaceholder = intl.formatMessage({ id: "please_select" });

    const finalOptions = useMemo(
      () => [
        { value: "", label: defaultPlaceholder, disabled: true },
        ...options,
      ],
      [options, defaultPlaceholder]
    );

    const renderValue =
      options.length > 0
        ? (value: any) =>
            options.find((option) => option.value === value)?.label || (
              <span className="placeholder">
                {placeholder || defaultPlaceholder}
              </span>
            )
        : undefined;

    const SelectComponent = (
      <StyledSelect
        ref={ref}
        displayEmpty
        fullWidth
        defaultValue=""
        renderValue={renderValue}
        MenuProps={{
          PaperProps: {
            sx: { maxHeight: 360, "& .MuiMenuItem-root": { fontSize: 14 } },
          },
        }}
        IconComponent={DownChevronIcon}
        value={value || ""}
        {...rest}
      >
        {children ||
          finalOptions.map(({ value, label, disabled }) => (
            <MenuItem key={value} value={value} disabled={disabled}>
              {label}
            </MenuItem>
          ))}
      </StyledSelect>
    );

    return toplabel ? (
      <Stack spacing={1}>
        <SmText className={`input-label${requiredlabel ? " required" : ""}`}>
          {toplabel}
        </SmText>
        {SelectComponent}
      </Stack>
    ) : (
      SelectComponent
    );
  }
);

const StyledSelect = styled(MuiSelect)(({ theme }) => ({
  minHeight: 37,
  ".MuiSelect-select": {
    padding: "9px 14px",
    backgroundColor: "#fff",
    "&,& p": {
      fontSize: 14,
    },
  },
  ".MuiInputBase-root": {},
  borderRadius: 8,
  [theme.breakpoints.down("md")]: {
    ".MuiSelect-select": {
      padding: "7px 14px",
      "&,& p": {
        fontSize: 12,
      },
    },
  },
}));

export default Select;
