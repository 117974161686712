import { SVGProps } from "react";

export function ShareIcon({
  color = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={13}
      viewBox="0 0 14 13"
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <path
        d="M13 6.62793L7.5 1.37793V4.37793C2.23656 4.37793 1 8.15199 1 11.8779C2.51906 9.93293 3.8625 8.87793 7.5 8.87793V11.8779L13 6.62793Z"
        stroke="#008934"
        strokeLinejoin="round"
      />
    </svg>
  );
}
