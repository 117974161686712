import { styled, Typography, TypographyProps } from "@mui/material";
import { COLORS } from "constants/layout";

const ResultText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: COLORS.gray[600],
  fontSize: 12,
  "& .qlt": {
    fontWeight: 500,
    color: COLORS.green[600],
  },
  "& .search": {
    // Add your styles here
  },
}));

export default ResultText;
