import { withLoading } from "hocs/withLoading";
import { lazy } from "react";

export { default as UnlockChatButton } from "./UnlockChatButton";
export { default as DisclaimerButton } from "./DisclaimerButton";
export { default as RegisterButton } from "./RegisterButton";
export { default as TextButton } from "./TextButton";
export { default as SwitchModeButton } from "./SwitchModeButton";
export { default as GreenButton } from "./GreenButton";
export const ComplaintButton = withLoading(
  lazy(() => import("./ComplaintButton"))
);
export const ReviewButton = withLoading(lazy(() => import("./ReviewButton")));
export { default as BizChatButton } from "./BizChatButton";
