import { LOCALSTORAGE_KEYS, settingStorage } from "helpers/localStorage";
import { isSameDomain } from "helpers/url";
import { useCallback, useMemo } from "react";
import {
  useLocation,
  useNavigate as useNavigateLib,
  useParams,
} from "react-router-dom";
import useUser from "./useUser";

const useNavigate = () => {
  const { search, pathname } = useLocation();
  const location = pathname;
  const navigate = useNavigateLib();
  const params = useParams();
  const { isLoggedIn } = useUser();

  const useSearchParams = useMemo(() => new URLSearchParams(search), [search]);

  const openNewTab = useCallback((url: string) => {
    window.open(url);
  }, []);

  const onShowSwitchAccount = (url: string) => {
    const passLocalStorageSwitch =
      settingStorage.get(LOCALSTORAGE_KEYS.showSwitchAccount) === 0;
    if (passLocalStorageSwitch || isSameDomain(url) || !isLoggedIn) {
      window.location.href = url;
    }
  };

  const onDirectKeepSearch = (
    path: string,
    s: string = "",
    replace: boolean = false
  ) =>
    navigate(
      {
        pathname: path,
        search: search + (s ? `${search ? "&" : ""}${s}` : ""),
      },
      { replace }
    );

  return {
    pathname,
    search,
    navigate,
    location,
    useSearchParams,
    params,
    openNewTab,
    onShowSwitchAccount,
    onDirectKeepSearch,
  };
};

export default useNavigate;
