import {
  checkListProtocols,
  getMatchImageSize,
  getThumbnail,
} from "services/utils/helpers";
import { FILE_DOMAIN, RESIZE_IMG_DOMAIN } from "constants/schemas";
import { DEFAULT_IMG } from "constants/img";
import { OptionType } from "types/common";
import { UserPlanType } from "types/supplier";
import dayjs from "dayjs";
import { PLAN_NAMES, SUPPLIER_PLANS } from "constants/data";

export const formatImg = (
  url?: string,
  type?: number,
  isThumbnail?: boolean
) => {
  return url
    ? checkListProtocols(url)
      ? url
      : checkIsVideo(url)
      ? isThumbnail
        ? getThumbnail(url, type)
        : `${RESIZE_IMG_DOMAIN}${url}`
      : type
      ? `${RESIZE_IMG_DOMAIN}/${getMatchImageSize(type)}${url}`
      : `${FILE_DOMAIN}${url}`
    : DEFAULT_IMG;
};

export const checkIsVideo = (suffix: string) => {
  let isVideo = false;
  if (suffix) {
    isVideo = suffix?.indexOf(".m3u8") > -1;
  }
  return isVideo;
};

export const removeSameOption = (options: OptionType[]) => [
  ...new Map(options.map((item: OptionType) => [item.value, item])).values(),
];

export const followRel = (no_follow: number) =>
  no_follow === 1 ? "nofollow" : undefined;


export const getCurrentPlan = (plan?: UserPlanType) => {
  const now = dayjs();

  if (!plan) return null;

  return {
    ...(SUPPLIER_PLANS.find((p) => p.label === plan?.plan_name) ||
      SUPPLIER_PLANS.find((p) => p.label === PLAN_NAMES.free)),
    ...plan,
    isExpired: dayjs(plan.end_date).isBefore(now),
  };
};