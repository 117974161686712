import { SVGProps } from "react";

export function SearchIcon({
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={22}
      height={22}
      viewBox={"0 0 22 22"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1822 14.8858C18.5987 11.8276 18.3953 7.37618 15.5717 4.55263C12.5289 1.5098 7.59547 1.5098 4.55263 4.55263C1.5098 7.59547 1.5098 12.5289 4.55263 15.5717C7.37618 18.3953 11.8276 18.5987 14.8858 16.1822L18.1644 19.4608C18.5224 19.8188 19.1028 19.8188 19.4608 19.4608C19.8188 19.1028 19.8188 18.5224 19.4608 18.1644L16.1822 14.8858ZM14.2754 5.849C16.6022 8.17587 16.6022 11.9485 14.2754 14.2754C11.9485 16.6022 8.17587 16.6022 5.849 14.2754C3.52212 11.9485 3.52212 8.17587 5.849 5.849C8.17587 3.52212 11.9485 3.52212 14.2754 5.849Z"
        fill="#667085"
      />
    </svg>
  );
}
