import {
  Avatar,
  Box,
  Dialog,
  Divider,
  Link,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { FiUser } from "react-icons/fi";

import { Img, IntlMsg, SmTextMd, XsText, XsTextMd } from "components";
import useAuth from "hooks/useAuth";
import { useIntl } from "react-intl";
import { ReactNode, useMemo, useState } from "react";
import { DIRECT_PATHS, PATHS } from "constants/routes";
import { formatImg, getCurrentPlan } from "helpers/format";
import {
  BagIcon,
  CartIcon,
  CircleCheckIcon,
  CrownIcon,
  LogoutIcon,
  SendIcon,
  ShipIcon,
  SmChatFAQIcon,
  SmLockIcon,
  SmNoteIcon,
  SmPackageIcon,
  SmRoundedQuestionIcon,
  SmSettingIcon,
  SmTargetIcon,
} from "assets/icons";
import useUser from "hooks/useUser";
import { menuCountDataSelector } from "services/redux/selectors/userCommonData";
import { COLORS, MAX_NOTI_CHAR_QLT } from "constants/layout";
import { PlanChip } from "../Plan";
import BuyerVerfifyTooltip from "components/tooltip/BuyerVerifyTooltip";
import { StyledAuthButton, StyledMenu } from "./AuthBar.styles";
import ConfirmButtons from "components/button/ConfirmButtons";
import SupplierVerfifyTooltip from "components/tooltip/SupplierVerifyTooltip";

const AuthBar = ({ hideUserName = false }) => {
  const { user, userContactQuote, userPoint, isFreePlan } = useUser();
  const { buyer, supplier } = user;

  const t = useIntl();
  const { isLoggedIn, onLogout, onDirectLogin } = useAuth();
  const menuCountStoreData = useSelector(menuCountDataSelector);
  const [openDialog, setOpenDialog] = useState(false);
  const [menuConfig, setMenuConfig] = useState<{
    open: boolean;
    anchorEl?: any;
  }>({
    open: false,
    anchorEl: undefined,
  });

  const formatUserName = (name = "") => {
    return name && name?.length > 0 && name[0];
  };

  const handleClickLogin = (e: any) => {
    if (isLoggedIn) {
      setMenuConfig({ open: true, anchorEl: e.currentTarget });
    } else {
      onDirectLogin();
    }
  };

  const onConfirmLogout = () => {
    onLogout();
    onCloseDialog();
    setMenuConfig({ open: false });
  };

  const supplierMenuItems = useMemo(
    () => [
      {
        label: "all_buying_leads",
        href: PATHS.sourcingRequestsFull,
        icon: SmTargetIcon,
      },
      {
        label: "list_your_product",
        href: DIRECT_PATHS.itemDetail("create", true),
        icon: SmPackageIcon,
      },
      {
        label: "verify_business_account",
        href: PATHS.supplierMembership,
        icon: CircleCheckIcon,
      },
      {
        label: "become_premium_supplier",
        href: PATHS.pricing,
        icon: CrownIcon,
        labelStyles: { color: COLORS.green[600] },
        iconStyles: { stroke: COLORS.green[600] },
      },
      {
        label: "quotation_sent",
        href: PATHS.myQuotationsFull,
        icon: BagIcon,
      },
      {
        label: "inquiries_received",
        href: PATHS.inquiriesFull,
        count: menuCountStoreData?.count_inquiries,
        icon: SmNoteIcon,
      },
      {
        label: "edit_supplier_profile",
        href: PATHS.settingFull,
        icon: SmSettingIcon,
      },
      {
        label: "supplier_centre",
        href: PATHS.supplierCenter,
        icon: SmRoundedQuestionIcon,
      },
    ],
    [menuCountStoreData]
  );

  const buyerMenuItems = useMemo(
    () => [
      {
        label: "get_quotes_from_suppliers",
        href: PATHS.postRfq,
        icon: SmTargetIcon,
      },
      {
        label: "my_request_for_quotation",
        href: PATHS.buyerRequestQuotation,
        icon: SendIcon,
        openNewTab: true,
      },
      {
        label: "verify_business_account",
        href: PATHS.buyerMembership,
        icon: CircleCheckIcon,
      },
      {
        label: "inquiries_sent",
        href: PATHS.buyerInquiries,
        icon: SmNoteIcon,
      },
      {
        label: "quotations_received",
        href: PATHS.buyerReceivedQuotation,
        count: menuCountStoreData?.count_quotations,
        icon: BagIcon,
      },
      {
        label: "edit_buyer_profile",
        href: PATHS.buyerProfile,
        icon: SmSettingIcon,
      },
      {
        label: "buyer_centre",
        href: PATHS.buyerCenter,
        icon: SmRoundedQuestionIcon,
      },
    ],
    [menuCountStoreData]
  );

  const commonMenuItems = useMemo(
    () => [
      {
        label: "change_your_password",
        href: PATHS.resetPassword,
        icon: SmLockIcon,
      },
      {
        label: "sign_out",
        event: () => setOpenDialog(true),
        icon: LogoutIcon,
      },
    ],
    []
  );

  const onCloseMenu = () => setMenuConfig({ open: false });

  const onCloseDialog = () => setOpenDialog(false);

  const PointValue = () => {
    return (
      <>
        {t.formatMessage(
          {
            id:
              userContactQuote > 1
                ? "value_contact_credits"
                : "value_contact_credit",
          },
          {
            v: userContactQuote,
            s: (code: ReactNode) => <span>{code}</span>,
          }
        )}

        <Divider flexItem orientation="vertical" />

        {t.formatMessage(
          {
            id: userPoint > 1 ? "value_quotes_left" : "value_quote_left",
          },
          {
            v: userPoint,
            s: (code: ReactNode) => <span>{code}</span>,
          }
        )}
      </>
    );
  };

  return (
    <>
      <StyledAuthButton
        variant="text"
        startIcon={
          isLoggedIn ? (
            <Avatar alt="icon" src={formatImg(user?.avatar, 1)}>
              {user?.first_name && formatUserName(user?.first_name)}
            </Avatar>
          ) : (
            <FiUser />
          )
        }
        onClick={handleClickLogin}
        hideUserName={hideUserName}
      >
        {!isLoggedIn && (
          <SmTextMd className="username" align="left">
            {t.formatMessage({ id: "login" })}
          </SmTextMd>
        )}
        {!hideUserName && isLoggedIn && (
          <Stack>
            <SmTextMd className="username" align="left">
              {user?.first_name}
            </SmTextMd>
            <Typography
              fontSize={10}
              className="quote-text ellipsis-text"
              fontWeight={500}
            >
              <PointValue />
            </Typography>
          </Stack>
        )}
      </StyledAuthButton>

      {/* Dropdown content */}
      <StyledMenu
        open={menuConfig.open}
        anchorEl={menuConfig.anchorEl}
        onClose={onCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {/* User informations */}
        <Box className="user-info">
          <Stack direction="row" spacing={1}>
            <Img src={formatImg(user.avatar, 3)} className="avatar" />
            <Stack spacing={0.25} maxWidth="calc(100% - 68px)">
              <SmTextMd className="cutoff-text oneline">
                {user.first_name}
              </SmTextMd>
              <XsText className="ellipsis-text" color={COLORS.gray[500]}>
                {user?.email}
              </XsText>
              <XsText fontSize={10} className="credit-text ellipsis-text">
                <PointValue />
              </XsText>
            </Stack>
          </Stack>
        </Box>

        {/* Supplier informations */}
        <Link href={PATHS.overviewFull}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            my={1}
            justifyContent="center"
          >
            <ShipIcon />
            <SmTextMd color={COLORS.green[600]}>
              {t.formatMessage({ id: "selling" })}
            </SmTextMd>
            {!isFreePlan && (
              <PlanChip plan={getCurrentPlan(supplier?.plan)?.plan_name} />
            )}
            <SupplierVerfifyTooltip data={supplier?.plan} />
          </Stack>
        </Link>
        <Divider />
        {supplierMenuItems?.map((menuItem, index) => {
          const { labelStyles, label, iconStyles, href } = menuItem;
          const count = menuItem.count;
          return (
            <MenuItem key={index} href={href} component="a">
              <menuItem.icon width={15} {...iconStyles} />
              <XsTextMd ml={0.5} {...labelStyles}>
                {t.formatMessage({ id: label })}
              </XsTextMd>
              {Number(count) > 0 && (
                <p className={"badge-number"}>
                  {Math.min(count, MAX_NOTI_CHAR_QLT)}
                  {count > MAX_NOTI_CHAR_QLT ? "+" : ""}
                </p>
              )}
            </MenuItem>
          );
        })}

        {/* Buyer informations */}
        <Link href={PATHS.buyerOverview}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            my={1}
            justifyContent="center"
          >
            <CartIcon />
            <SmTextMd color={COLORS.green[600]}>
              {t.formatMessage({ id: "buying" })}
            </SmTextMd>
            <PlanChip plan={getCurrentPlan(buyer?.plan)?.plan_name} />
            <BuyerVerfifyTooltip
              data={{ is_biz_verified: buyer?.plan.is_biz_verified }}
            />
          </Stack>
        </Link>
        <Divider />
        {buyerMenuItems?.map((menuItem, index) => {
          const { label, href } = menuItem;
          const count = menuItem.count;
          return (
            <MenuItem key={index} href={href} component="a">
              <menuItem.icon width={15} />
              <XsTextMd ml={0.5}>{t.formatMessage({ id: label })}</XsTextMd>
              {Number(count) > 0 && (
                <p className={"badge-number"}>
                  {Math.min(count, MAX_NOTI_CHAR_QLT)}
                  {count > MAX_NOTI_CHAR_QLT ? "+" : ""}
                </p>
              )}
            </MenuItem>
          );
        })}

        <Divider />
        <Stack pb={1.5}>
          {commonMenuItems?.map((menuItem, index) => {
            const { label, href, event } = menuItem;
            return (
              <MenuItem key={index} href={href} component="a" onClick={event}>
                <menuItem.icon width={15} />
                <XsTextMd ml={0.5}>{t.formatMessage({ id: label })}</XsTextMd>
              </MenuItem>
            );
          })}
        </Stack>
      </StyledMenu>

      <Dialog
        open={openDialog}
        onClose={onCloseDialog}
        PaperProps={{ sx: { width: "100%", maxWidth: 360 } }}
      >
        <Stack spacing={1} p={2}>
          <Typography>
            <IntlMsg id="confirm_logout" />
          </Typography>
          <ConfirmButtons
            onCancle={onCloseDialog}
            onConfirm={onConfirmLogout}
          />
        </Stack>
      </Dialog>
    </>
  );
};

export default AuthBar;
