import { Stack, styled } from "@mui/material";
import { Img } from "../Img";
import { TextSemibold } from "../Text";
import { DIRECT_PATHS } from "constants/routes";
import CountryFlag from "../CountryFlag";
import { useIntl } from "react-intl";
import Row from "../Row";
import { PlanChip } from "../Plan";
import { APP_ICONS } from "assets/icons";
import { ElementType } from "react";
import { ALink } from "../link";
import { COLORS } from "constants/layout";
// import { CompanyIcon } from "assets/icons/company.icon";
import { formatImg } from "helpers/format";
import { SupplierType } from "types/supplier";

const SupplierInfors = ({
  supplier,
  nameComponent = "h2",
}: {
  supplier?: SupplierType;
  nameComponent?: ElementType;
}) => {
  const t = useIntl();
  const {
    country,
    country_code,
    name,
    farm_avatar,
    // farm_name,
    plan_name,
    is_verified,
    salutation,
    position,
    farmer_name,
  } = supplier || {};

  return (
    <StyledBaseSupplierInfors
      direction="row"
      alignItems="flex-start"
      spacing={1}
    >
      {farm_avatar && (
        <Img
          src={formatImg(farm_avatar, 0)}
          alt="img"
          className="company_img"
        />
      )}
      <Stack
        spacing={0.5}
        flex={1}
        maxWidth={`calc(100% - ${farm_avatar ? "48px" : "0px"})`}
      >
        {farmer_name && (
          <ALink href={DIRECT_PATHS.supplierDetail(name)}>
            <TextSemibold
              className="company_name ellipsis-text"
              component={nameComponent}
            >
              {salutation ? `${salutation}. ` : ""}
              {farmer_name}
              {position && <span> ({position})</span>}
            </TextSemibold>
          </ALink>
        )}

        {/* {farm_name && (
          <ALink href={DIRECT_PATHS.supplierDetail(name)}>
            <Row spacing={0.5}>
              <CompanyIcon />
              <XsText className="ellipsis-text" color={COLORS.gray[700]}>
                {farm_name}
              </XsText>
            </Row>
          </ALink>
        )} */}

        <Row spacing={0.5} mb={0.5} minHeight={20}>
          <PlanChip plan={plan_name} />
          {is_verified === 1 && (
            <Img
              src={APP_ICONS.verified}
              alt="icon"
              className="verified-icon"
              height={12}
            />
          )}
          <CountryFlag {...{ country_code, country }} />
        </Row>
      </Stack>
    </StyledBaseSupplierInfors>
  );
};

const StyledBaseSupplierInfors = styled(Stack)({
  fontSize: 16,
  width: "100%",
  ".company_name": {
    color: "#1D2939",
    span: { color: COLORS.gray[600], fontSize: 12, fontWeight: 400 },
  },
  ".company_img": {
    width: 40,
    height: 40,
    objectFit: "contain",
  },
  ".flag": {
    width: 16,
    height: 16,
  },
  ".active-text": {
    color: "var(--gray-600)",
  },
});

export default SupplierInfors;
