import { Stack, styled } from "@mui/material";
import { RoundInfoIcon } from "assets/icons";
import { SmText } from "components/Text";
import { COLORS } from "constants/layout";
import { useTranslation } from "hooks/useTranslation";
import React from "react";

type Props = {
  text?: string;
};

const AttentionText = ({ text = "create_quotation_attention" }: Props) => {
  const t = useTranslation();
  return (
    <StyledStack direction="row" spacing={0.5} className="attention-box">
      <RoundInfoIcon
        fill={COLORS.yellow[500]}
        stroke="#fff"
        width={20}
        height={20}
      />
      <SmText color={COLORS.yellow[600]} className="attention-text">
        <span style={{ fontWeight: 500 }}>{t("attention")}</span>: {t(text)}
      </SmText>
    </StyledStack>
  );
};

const StyledStack = styled(Stack)(({ theme }) => ({
  svg: {
    minWidth: 20,
  },
  backgroundColor: COLORS.yellow[25],
  padding: 8,
  borderRadius: 8,
  [theme.breakpoints.down("md")]: {
    ".attention-text": {
      fontSize: 12,
    },
  },
}));

export default AttentionText;
