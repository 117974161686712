import { SVGProps } from "react";

export function TargetIcon({
  width = 20,
  height = 20,
  viewBox = "0 0 20 20",
  stroke = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg {...{ width, height, viewBox }} fill="none" xmlns={xmlns} {...props}>
      <path
        d="M8.51998 5.73655C8.96553 5.64744 9.32197 5.11278 9.14375 4.66722C9.05464 4.22167 8.51998 3.86523 8.07442 4.04345C5.66844 4.57811 4.06445 6.71676 4.06445 9.21186C4.06445 12.1525 6.47044 14.5585 9.41108 14.5585C12.1735 14.5585 14.4904 12.4198 14.7577 9.65741C14.8468 9.21186 14.4013 8.76631 13.9557 8.6772C13.5102 8.58808 13.0646 9.03364 12.9755 9.47919C12.7973 11.2614 11.1933 12.7763 9.41108 12.7763C7.45065 12.7763 5.84666 11.1723 5.84666 9.21186C5.84666 7.51876 6.91599 6.09299 8.51998 5.73655Z"
        fill="#667085"
      />
      <path
        d="M17.431 8.3207C16.8963 8.3207 16.5399 8.67714 16.5399 9.2118C16.5399 13.1327 13.3319 16.3406 9.41105 16.3406C5.49019 16.3406 2.28221 13.1327 2.28221 9.2118C2.28221 5.46916 5.22286 2.3503 8.9655 2.08297C9.50016 2.08297 9.8566 1.63741 9.76749 1.10275C9.76749 0.568088 9.32194 0.211646 8.78728 0.300756C4.15353 0.657198 0.5 4.48895 0.5 9.2118C0.5 14.1129 4.50997 18.1229 9.41105 18.1229C14.3121 18.1229 18.3221 14.1129 18.3221 9.2118C18.3221 8.67714 17.8765 8.3207 17.431 8.3207Z"
        fill="#667085"
      />
      <path
        d="M11.1037 6.27115L8.78686 8.58802C8.43042 8.94446 8.43042 9.47913 8.78686 9.83557C8.96508 10.0138 9.23241 10.1029 9.41064 10.1029C9.58886 10.1029 9.85619 10.0138 10.0344 9.83557L12.3513 7.5187L13.8662 7.96425C13.9553 7.96425 14.0444 7.96425 14.1335 7.96425C14.4008 7.96425 14.579 7.87514 14.7573 7.69692L18.2326 4.1325C18.4108 3.95428 18.4999 3.59784 18.4999 3.3305C18.4999 3.06317 18.2326 2.79584 17.9652 2.70673L16.3613 2.17207L15.8266 0.568079C15.7375 0.300748 15.4701 0.0334164 15.2028 0.0334164C14.9355 -0.055694 14.579 0.0334164 14.4008 0.300748L10.9255 3.86517C10.6582 4.1325 10.5691 4.48894 10.7473 4.75627L11.1037 6.27115ZM14.579 2.61762L14.8464 3.24139C14.9355 3.50872 15.1137 3.68695 15.381 3.77606L16.0048 4.04339L13.8662 6.18204L12.7968 5.8256L12.4404 4.75627L14.579 2.61762Z"
        fill="#667085"
      />
    </svg>
  );
}

export function SmTargetIcon({
  color = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={16}
      viewBox={"0 0 15 16"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M6.875 5.56241C7.1875 5.49991 7.4375 5.12491 7.3125 4.81241C7.25 4.49991 6.875 4.24991 6.5625 4.37491C4.875 4.74991 3.75 6.24991 3.75 7.99991C3.75 10.0624 5.4375 11.7499 7.5 11.7499C9.4375 11.7499 11.0625 10.2499 11.25 8.31241C11.3125 7.99991 11 7.68741 10.6875 7.62491C10.375 7.56241 10.0625 7.87491 10 8.18741C9.875 9.43741 8.75 10.4999 7.5 10.4999C6.125 10.4999 5 9.37491 5 7.99991C5 6.81241 5.75 5.81241 6.875 5.56241Z"
        fill="#667085"
      />
      <path
        d="M13.125 7.37493C12.75 7.37493 12.5 7.62493 12.5 7.99993C12.5 10.7499 10.25 12.9999 7.5 12.9999C4.75 12.9999 2.5 10.7499 2.5 7.99993C2.5 5.37493 4.5625 3.18743 7.1875 2.99993C7.5625 2.99993 7.8125 2.68743 7.75 2.31243C7.75 1.93743 7.4375 1.68743 7.0625 1.74993C3.8125 1.99993 1.25 4.68743 1.25 7.99993C1.25 11.4374 4.0625 14.2499 7.5 14.2499C10.9375 14.2499 13.75 11.4374 13.75 7.99993C13.75 7.62493 13.4375 7.37493 13.125 7.37493Z"
        fill="#667085"
      />
      <path
        d="M8.6875 5.9375L7.0625 7.5625C6.8125 7.8125 6.8125 8.1875 7.0625 8.4375C7.1875 8.5625 7.375 8.625 7.5 8.625C7.625 8.625 7.8125 8.5625 7.9375 8.4375L9.5625 6.8125L10.625 7.125C10.6875 7.125 10.75 7.125 10.8125 7.125C11 7.125 11.125 7.0625 11.25 6.9375L13.6875 4.4375C13.8125 4.3125 13.875 4.0625 13.875 3.875C13.875 3.6875 13.6875 3.5 13.5 3.4375L12.375 3.0625L12 1.9375C11.9375 1.75 11.75 1.5625 11.5625 1.5625C11.375 1.5 11.125 1.5625 11 1.75L8.5625 4.25C8.375 4.4375 8.3125 4.6875 8.4375 4.875L8.6875 5.9375ZM11.125 3.375L11.3125 3.8125C11.375 4 11.5 4.125 11.6875 4.1875L12.125 4.375L10.625 5.875L9.875 5.625L9.625 4.875L11.125 3.375Z"
        fill="#667085"
      />
    </svg>
  );
}
