import { Button, styled } from "@mui/material";
import { COLORS } from "constants/layout";
import useAuth from "hooks/useAuth";
import { useTranslation } from "hooks/useTranslation";

const RegisterButton = () => {
  const t = useTranslation();
  const { onDirectSignup } = useAuth();
  const onClickRegister = () => {
    onDirectSignup();
  };

  return (
    <StyledRegisterButton onClick={onClickRegister}>
      {t("free_signup")}
    </StyledRegisterButton>
  );
};

const StyledRegisterButton = styled(Button)({
  "&,:hover": {
    backgroundColor: COLORS.yellow[500],
  },
  fontWeight: 500,
  fontSize: 14,
  color: "#fff",
  textTransform: "initial",
});

export default RegisterButton;
