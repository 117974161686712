import { SVGProps } from "react";

export function BagIcon({
  width = 25,
  height = 24,
  viewBox = "0 0 25 24",
  color = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <path
        d="M16.5 21V5C16.5 4.46957 16.2893 3.96086 15.9142 3.58579C15.5391 3.21071 15.0304 3 14.5 3H10.5C9.96957 3 9.46086 3.21071 9.08579 3.58579C8.71071 3.96086 8.5 4.46957 8.5 5V21M4.5 7H20.5C21.6046 7 22.5 7.89543 22.5 9V19C22.5 20.1046 21.6046 21 20.5 21H4.5C3.39543 21 2.5 20.1046 2.5 19V9C2.5 7.89543 3.39543 7 4.5 7Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SellBagIcon({
  width = 18,
  height = 18,
  viewBox = "0 0 18 18",
  stroke = "#008934",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <path
        d="M2.25 4.5L4.5 1.5H13.5L15.75 4.5M2.25 4.5V15C2.25 15.3978 2.40804 15.7794 2.68934 16.0607C2.97064 16.342 3.35218 16.5 3.75 16.5H14.25C14.6478 16.5 15.0294 16.342 15.3107 16.0607C15.592 15.7794 15.75 15.3978 15.75 15V4.5M2.25 4.5H15.75M12 7.5C12 8.29565 11.6839 9.05871 11.1213 9.62132C10.5587 10.1839 9.79565 10.5 9 10.5C8.20435 10.5 7.44129 10.1839 6.87868 9.62132C6.31607 9.05871 6 8.29565 6 7.5"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
