import { BaseButton } from "../Button";
import { Dialog, DialogProps, IconButton, Stack, styled } from "@mui/material";
import { LgTextSemiBold, TextMd } from "../Text";
import { QuotationType } from "types/quotaion";
import Quotation from "../Quotation";
import Row from "../Row";
import { useTranslation } from "hooks/useTranslation";
import { CloseIcon } from "assets/icons";
import BaseBuyerInfors from "../infos/BaseBuyerInfors";
import { GreenButton } from "components/button";
import { AttentionText } from "components/text";
import { COLORS } from "constants/layout";
import { useMemo } from "react";
import dayjs from "dayjs";

type Props = {
  reviewQuote?: QuotationType;
  onClickSendQuotation: () => void;
};

const RFQReviewPopup = ({
  reviewQuote,
  onClickSendQuotation,
  ...rest
}: Props & DialogProps) => {
  const { title, buyer } = reviewQuote || {};
  const t = useTranslation();

  const onClose = (e: any) => rest.onClose?.(e, "backdropClick");

  const buyerIsInactive = useMemo(
    () =>
      !buyer?.last_active ||
      Math.abs(dayjs().diff(dayjs(buyer?.last_active), "day")) > 30,
    [buyer?.last_active]
  );

  const buyerNotVerified = useMemo(
    () =>
      !(buyer?.is_biz_verified === 1 || buyer?.is_verified_work_email === 1),
    [buyer]
  );

  return (
    <StyledDialog
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: 720,
          m: 2,
        },
      }}
      {...rest}
    >
      <Stack p={2} spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={1}
          alignItems="flex-start"
        >
          <LgTextSemiBold>
            {t("confirm_quotation_and_buyer_infor", {
              buyer: buyer?.customer_name,
              title: title,
            })}
          </LgTextSemiBold>
          <IconButton onClick={onClose} className="fit">
            <CloseIcon />
          </IconButton>
        </Stack>

        <Stack spacing={1}>
          <TextMd>{t("buyer_information")}</TextMd>
          <BaseBuyerInfors company={buyer} />
        </Stack>

        <Stack spacing={1}>
          <TextMd>{t("your_quotation_details")}</TextMd>
          {reviewQuote && (
            <Quotation
              quotation={reviewQuote}
              p={0}
              boxShadow="none"
              hideActions
            />
          )}
        </Stack>

        {(buyerIsInactive || buyerNotVerified) && (
          <AttentionText
            text={
              buyerIsInactive
                ? "attention_buyer_inactive"
                : buyerNotVerified
                ? "attention_buyer_not_verified"
                : ""
            }
          />
        )}

        <Row justifyContent="flex-end" className="buttons">
          <BaseButton onClick={(e) => rest?.onClose?.(e, "escapeKeyDown")}>
            {t("go_back")}
          </BaseButton>
          <GreenButton onClick={onClickSendQuotation}>
            {t("send_quotation")}
          </GreenButton>
        </Row>
      </Stack>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  ".cost": {},
  ".buttons": {
    button: {
      width: "fit-content",
      height: 44,
    },
  },

  [theme.breakpoints.down("md")]: {
    ".buttons": {
      button: {
        height: 40,
      },
    },
  },
}));

export default RFQReviewPopup;
