import { SVGProps } from "react";

export function LoginIcon({
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={15}
      viewBox={"0 0 15 15"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M9.375 1.86804H11.875C12.2065 1.86804 12.5245 1.99974 12.7589 2.23416C12.9933 2.46858 13.125 2.78652 13.125 3.11804V11.868C13.125 12.1996 12.9933 12.5175 12.7589 12.7519C12.5245 12.9863 12.2065 13.118 11.875 13.118H9.375M6.25 10.618L9.375 7.49304M9.375 7.49304L6.25 4.36804M9.375 7.49304H1.875"
        stroke="#667085"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
