import { SVGProps } from "react";

export function NoteIcon({
  width = 24,
  height = 24,
  viewBox = "0 0 24 24",
  color = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <path
        d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8M14 2L20 8M14 2V8H20M16 13H8M16 17H8M10 9H8"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SmNoteIcon({
  color = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={16}
      viewBox="0 0 15 16"
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <path
        d="M8.75 1.75H3.75C3.41848 1.75 3.10054 1.8817 2.86612 2.11612C2.6317 2.35054 2.5 2.66848 2.5 3V13C2.5 13.3315 2.6317 13.6495 2.86612 13.8839C3.10054 14.1183 3.41848 14.25 3.75 14.25H11.25C11.5815 14.25 11.8995 14.1183 12.1339 13.8839C12.3683 13.6495 12.5 13.3315 12.5 13V5.5M8.75 1.75L12.5 5.5M8.75 1.75V5.5H12.5M10 8.625H5M10 11.125H5M6.25 6.125H5"
        stroke="#667085"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
