import { Button, styled } from "@mui/material";
import { COLORS } from "constants/layout";

const WhiteButton = styled(Button)({
  textTransform: "initial",
  borderRadius: 8,
  fontWeight: 500,
  padding: "8px 16px",
  fontSize: 14,
  border: `1px solid ${COLORS.gray[500]}`,
  color: COLORS.gray[500],
});

export default WhiteButton;
