import { withSeparateApp } from "hocs/withSeparateApp";
import AppProvider from "providers/app";
import NotificationHandler from "../helpers/notification";
import { ScrollToTop } from "components";

const AppRoutes = () => {
  return (
    <>
      <AppProvider>{withSeparateApp()}</AppProvider>
      <NotificationHandler />
      <ScrollToTop />
    </>
  );
};

export default AppRoutes;
