import { Button, styled } from "@mui/material";

const ButtonBase = styled(Button)({
  textTransform: "initial",
  borderRadius: 8,
  fontWeight: "bold",
  padding: "8px 16px",
  fontSize: 14,
});

export default ButtonBase;
