import {
  Collapse,
  MenuItem,
  Stack,
  StackProps,
  styled,
  TypographyProps,
} from "@mui/material";
import Row from "../Row";
import { memo, SVGProps, useState } from "react";
import { PATHS } from "constants/routes";
import Menu from "../Menu";
import { OpenTabLink, SmTextMd, XsTextMd } from "../Text";
import {
  CIRCLE_CROWN_ICON,
  LoginIcon,
  SmChatFAQIcon,
  SmCrownIcon,
  SmEditIcon,
  SmLinkIcon,
  SmPackageIcon,
  SmRoundedQuestionIcon,
  SmTargetIcon,
  SmVerifyIcon,
} from "assets/icons";
import { Img } from "../Img";
import { COLORS } from "constants/layout";
import useBreakpoints from "hooks/useBreakpoint";
import { useTranslation } from "hooks/useTranslation";
import { ALink, NoUnderlineALink } from "../link";
import useUser from "hooks/useUser";
import { TextButton } from "components/button";
import { DownChevronIcon } from "assets/icons/chevron.icon";

type ServicesType = {
  label: string;
  options: {
    label: string;
    href: string;
    icon: (props: any) => JSX.Element;
    labelStyles?: TypographyProps;
    iconStyles?: SVGProps<SVGSVGElement>;
    disabled?: boolean;
  }[];
};

const HeaderServices = (props: StackProps) => {
  const { isLoggedIn } = useUser();
  const { isMobile } = useBreakpoints();
  const t = useTranslation();
  const [expandMenu, setExpandMenu] = useState({
    forBuyer: false,
    forSupplier: false,
  });

  const services: ServicesType[] = [
    {
      label: "for_supplier",
      options: [
        {
          label: "login_as_supplier",
          href: PATHS.loginFull,
          icon: LoginIcon,
          disabled: isLoggedIn,
        },
        {
          label: "free_supplier_sign_up",
          href: PATHS.registerSupplier,
          icon: SmEditIcon,
          disabled: isLoggedIn,
        },
        {
          label: "list_your_product",
          href: PATHS.createProductsFull,
          icon: SmPackageIcon,
        },
        {
          label: "verify_business_account",
          href: PATHS.membershipFull,
          icon: SmVerifyIcon,
        },
        {
          label: "find_potential_buying_leads",
          href: PATHS.sourcingRequestsFull,
          icon: SmTargetIcon,
        },
        {
          label: "connect_with_verified_buyers",
          href: PATHS.buyersFull,
          icon: SmLinkIcon,
        },
        {
          label: "become_premium_supplier",
          href: PATHS.pricing,
          icon: SmCrownIcon,
          labelStyles: { color: COLORS.green[600] },
          iconStyles: { stroke: COLORS.green[600] },
        },
        {
          label: "supplier_centre",
          href: PATHS.supplierCenter,
          icon: SmRoundedQuestionIcon,
        },
        {
          label: "faqs",
          href: PATHS.supplierFAQs,
          icon: SmChatFAQIcon,
        },
      ],
    },
    {
      label: "for_buyer",
      options: [
        {
          label: "login_as_buyer",
          href: PATHS.loginFull,
          icon: LoginIcon,
          disabled: isLoggedIn,
        },
        {
          label: "free_buyer_sign_up",
          href: PATHS.registerFull,
          icon: SmEditIcon,
          disabled: isLoggedIn,
        },
        {
          label: "get_quotes_from_suppliers",
          href: PATHS.postRfq,
          icon: SmTargetIcon,
        },
        {
          label: "verify_business_account",
          href: PATHS.buyerMembership,
          icon: SmVerifyIcon,
        },
        {
          label: "find_potential_sources",
          href: PATHS.buyerProductsFull,
          icon: SmPackageIcon,
        },
        {
          label: "connect_with_premium_supplier",
          href: PATHS.buyerSuppliersFull,
          icon: SmLinkIcon,
        },
        {
          label: "buyer_centre",
          href: PATHS.buyerCenter,
          icon: SmRoundedQuestionIcon,
        },
        {
          label: "faqs",
          href: PATHS.buyerFAQs,
          icon: SmChatFAQIcon,
        },
      ],
    },
  ];

  const onToggleButton = (i: number) => {
    setExpandMenu({
      ...expandMenu,
      ...(i === 0
        ? { forSupplier: !expandMenu.forSupplier }
        : { forBuyer: !expandMenu.forBuyer }),
    });
  };

  if (!isMobile) {
    return (
      <StyledHeaderServices direction="row" {...props}>
        <OpenTabLink href={PATHS.supplierPricing} className="app-link-btn">
          <SmTextMd className="premium-text">
            <Img src={CIRCLE_CROWN_ICON} alt="icon" />
            {t("premium_membership_origin")}
          </SmTextMd>
        </OpenTabLink>

        {services.map((service, i) => (
          <Row key={i}>
            <Menu
              buttonText={t(service.label)}
              buttonProps={{ className: "app-link-btn" }}
            >
              {service.options.map((menuItem, ii) => {
                const { href, iconStyles, labelStyles, label, disabled } =
                  menuItem;
                return (
                  <NoUnderlineALink
                    target="_blank"
                    key={ii}
                    href={disabled ? undefined : href}
                    rel="noopener noreferrer"
                  >
                    <StyledMenuItem disabled={disabled}>
                      <menuItem.icon width={15} {...iconStyles} />
                      <XsTextMd ml={0.5} {...labelStyles}>
                        {t(label)}
                      </XsTextMd>
                    </StyledMenuItem>
                  </NoUnderlineALink>
                );
              })}
            </Menu>
          </Row>
        ))}
      </StyledHeaderServices>
    );
  }

  return (
    <StyledHeaderServices spacing={1.5} {...props}>
      {services.map((service, i) => {
        const isExpanded =
          i === 0 ? expandMenu.forSupplier : expandMenu.forBuyer;

        return (
          <Stack key={i} className="mobile">
            <TextButton onClick={() => onToggleButton(i)}>
              <DownChevronIcon
                style={{
                  transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
              {t(service.label)}
            </TextButton>

            <Collapse in={isExpanded} unmountOnExit>
              {service.options.map((menuItem, ii) => {
                const { href, iconStyles, labelStyles, label, disabled } =
                  menuItem;
                const Icon = menuItem.icon;
                return (
                  <ALink
                    target="_blank"
                    key={`${service.label}-ii`}
                    href={disabled ? undefined : href}
                    rel="noopener noreferrer"
                    hideUnderline
                  >
                    <StyledMenuItem disabled={disabled}>
                      {Icon ? <Icon width={15} {...iconStyles} /> : null}
                      <XsTextMd ml={0.5} {...labelStyles}>
                        {t(label)}
                      </XsTextMd>
                    </StyledMenuItem>
                  </ALink>
                );
              })}
            </Collapse>
          </Stack>
        );
      })}
    </StyledHeaderServices>
  );
};

const StyledHeaderServices = styled(Stack)({
  ".value": {
    padding: "2px 8px",
    backgroundColor: "#F04438",
    fontSize: 12,
    color: "#fff",
    borderRadius: 16,
  },
  "a p.premium-text": {
    color: "var(--yellow-500)",
  },
  ".app-link-btn": {
    fontSize: 14,
    "&:hover, &:hover svg": {
      color: "var(--gray-700)",
    },
  },
  ".premium-text": {
    color: "var(--yellow-500)",
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  ".label": {
    // whiteSpace: "nowrap",
    color: "#667085",
  },
  ".mobile": {
    button: {
      width: "fit-content",
      gap: 8,
      fontWeight: 500,
    },
  },
});

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: "6px 0 !important",
  svg: {
    width: 20,
    height: 20,
    color: "#667085",
  },

  [theme.breakpoints.down("md")]: {
    padding: "5px 4px !important",
    minHeight: 28,
    svg: {
      width: 15,
      height: 15,
    },
  },
}));

export default memo(HeaderServices);
