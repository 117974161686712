import { Button, styled } from "@mui/material";
import { SmReloadIcon } from "assets/icons";
import { NoUnderlineALink } from "components/link";
import { COLORS } from "constants/layout";
import { PATHS } from "constants/routes";
import { useTranslation } from "hooks/useTranslation";
import React from "react";

type Props = {
  iconMode?: boolean;
};

const SwitchModeButton = ({ iconMode }: Props) => {
  const t = useTranslation();
  return (
    <NoUnderlineALink href={PATHS.buyerWorkspace}>
      <StyledSwitchModeButton
        className="switch-btn"
        sx={{
          border: iconMode ? "none" : `1px solid ${COLORS.blue[700]}`,
        }}
      >
        <SmReloadIcon />
        {!iconMode && t("switch_to_buyer_site")}
      </StyledSwitchModeButton>
    </NoUnderlineALink>
  );
};

const StyledSwitchModeButton = styled(Button)({
  fontWeight: 500,
  fontSize: 14,
  color: COLORS.blue[700],
  padding: "4px 10px",
  textTransform: "initial",
  lineHeight: "18px",
  borderRadius: 6,
  minWidth: 32,
  display: "flex",
  gap: 8,
});

export default SwitchModeButton;
