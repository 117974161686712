import { SVGProps } from "react";

export function RightChevronIcon({
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={19}
      viewBox={"0 0 18 19"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M6.75 13.8059L11.25 9.30591L6.75 4.80591"
        stroke="#101828"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function UpChevronIcon({
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      viewBox={"0 0 18 18"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M13.5 11.25L9 6.75L4.5 11.25"
        stroke="#667085"
        strokeWidth="1.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function DownChevronIcon({
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox={"0 0 20 20"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M5 7.5L10 12.5L15 7.5"
        stroke="#667085"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
