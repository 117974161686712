import { Stack, StackProps } from "@mui/material";
import { XsText } from "components/Text";
import { Tooltip } from "components/Tooltip";
import React, { memo } from "react";
import { ListingTagType } from "types/listing";

type Props = {
  tags?: ListingTagType[];
};

const ListingChip = ({ tags = [], ...rest }: Props & StackProps) => {
  if (!tags || tags?.length === 0) return null;
  return (
    <Stack
      gap={1}
      direction="row"
      flexWrap="wrap"
      maxHeight={22}
      overflow="hidden"
      {...rest}
    >
      {tags.map((tag: ListingTagType, i: number) => (
        <Tooltip key={i} title={tag?.description}>
          <div>
            <XsText className="tag-chip">{tag?.tag_name}</XsText>
          </div>
        </Tooltip>
      ))}
    </Stack>
  );
};

export default memo(ListingChip);
