import { SVGProps } from "react";

export function MenuIcon({
  xmlns = "http://www.w3.org/2000/svg",
  stroke = "#fff",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      viewBox={"0 0 18 18"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M6 4.5H15.75M6 9H15.75M6 13.5H15.75M2.25 4.5H2.2575M2.25 9H2.2575M2.25 13.5H2.2575"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function LgMenuIcon({
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox={"0 0 24 24"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M3 12H21M3 6H21M3 18H21"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
