import { Conversation, Message } from "@twilio/conversations";
import {
  ConversationAttributeType,
  MessageAttributeEmbedType,
  MessageAttributeType,
} from "types/chat";

export const getConversationAttributes = (
  conversation?: Conversation
): ConversationAttributeType => {
  if (
    conversation &&
    typeof conversation.attributes === "object" &&
    conversation.attributes !== null
  ) {
    return conversation.attributes as ConversationAttributeType;
  }
  return {};
};

export const getEmbedFromAttributes = (
  attributes: unknown
): MessageAttributeEmbedType | null => {
  try {
    const parsedAttributes =
      typeof attributes === "string" ? JSON.parse(attributes) : attributes;

    if (
      parsedAttributes &&
      typeof parsedAttributes === "object" &&
      "embed" in parsedAttributes
    ) {
      return (parsedAttributes as MessageAttributeType).embed || null;
    }

    return null; // Return null if no embed field exists
  } catch (error) {
    console.error("Error parsing or accessing embed from attributes:", error);
    return null;
  }
};

export const getMessageAttributes = (
  message?: Message
): MessageAttributeType => {
  try {
    if (typeof message?.attributes === "object") {
      const { embed, hidden } = message.attributes as any;
      return {
        embed,
        hidden: Array.isArray(hidden) ? hidden : undefined,
      };
    }
    return {};
  } catch (error) {
    console.error("Error parsing message attributes:", error);
  }

  return {};
};

export const CHAT_EMBED_OBJECT = {
  quotation: "Quotation",
  inquiry: "Inquiry",
  supplier: "Supplier",
};
