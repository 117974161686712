export const AI_PROCESSING_IMG = require("./ai_processing.gif");
export const QUOTE_LEFT_CONTACT_BLUR_IMG = require("./quote_left_to_contact.png");
export const CONTACT_INFO_BLUR_IMG = require("./blur/contact_info.png");

type BannerPaths = {
  [key: string]: string;
};

type BannersConfig = {
  desktop: BannerPaths;
  mobile: BannerPaths;
};

export const APP_IMGS: Record<"zh" | "vi" | "en" | "common", BannersConfig> = {
  zh: {
    desktop: {
      bannerRegister: "/images/zh/register_banner_desktop.webp",
      bannerBuyPlan: "/images/zh/buy_plan_banner_desktop.webp",
    },
    mobile: {
      bannerRegister: "/images/zh/register_banner_mobile.webp",
      bannerBuyPlan: "/images/zh/buy_plan_banner_mobile.webp",
    },
  },
  vi: {
    desktop: {
      bannerRegister: "/images/vi/register_banner_desktop.webp",
      bannerBuyPlan: "/images/vi/buy_plan_banner_desktop.webp",
    },
    mobile: {
      bannerRegister: "/images/vi/register_banner_mobile.webp",
      bannerBuyPlan: "/images/vi/buy_plan_banner_mobile.webp",
    },
  },
  en: {
    desktop: {
      bannerRegister: "/images/en/register_banner_desktop.webp",
      bannerBuyPlan: "/images/en/buy_plan_banner_desktop.webp",
    },
    mobile: {
      bannerRegister: "/images/en/register_banner_mobile.webp",
      bannerBuyPlan: "/images/en/buy_plan_banner_mobile.webp",
    },
  },
  common: {
    desktop: {
      blurInquiry: "/images/common/blur_inquiry_desktop.png",
      blurSendQuotation: "/images/common/blur_send_quotation.webp",
      blurInquiryChat: "/images/common/blur_inquiry_chat.png",
      blurQuotationChat: "/images/common/blur_quotation_chat.png",
      blurMessageChat: "/images/common/blur_message_chat.png",
    },
    mobile: {
      blurInquiry: "/images/common/blur_inquiry_mobile.png",
      blurSendQuotation: "/images/common/blur_send_quotation.webp",
    },
  },
};
