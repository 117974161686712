import { ButtonProps, styled } from "@mui/material";
import ButtonBase from "./BaseButton";
import { COLORS } from "constants/layout";

const GrayButton = (props: ButtonProps) => {
  return <StyledGrayButton {...props} />;
};

const StyledGrayButton = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: COLORS.gray[100],
  border: `1px solid ${COLORS.green[600]}`,
  color: COLORS.green[600],
  fontWeight: 500,
  "&:disabled": {
    backgroundColor: "#E5E5E5",
    color: "#fff",
    borderColor: "#E5E5E5",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
    padding: 8,
  },
}));

export default GrayButton;
