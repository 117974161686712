import { Provider } from "react-redux";
import { createStore } from "redux";
import { ThemeProvider } from "@mui/material";
import { BrowserRouter as Router } from "react-router-dom";

import rootReducer from "./services/reducer";
import { theme } from "./theme";
import AppRoutes from "routes/AppRoutes";
import LanguageProvider from "providers/mul-language";
import { GA4_MEASUREMENT_ID } from "./constants/schemas";
import ReactGA from "react-ga4";
import { LayoutProvider } from "providers/layout";

const store = createStore(rootReducer);

const isProd = process.env.NODE_ENV === "production";
if (isProd) {
  ReactGA.initialize(GA4_MEASUREMENT_ID || "");
}

function App() {
  return (
    <Provider store={store}>
      <LanguageProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <LayoutProvider>
              <AppRoutes />
            </LayoutProvider>
          </Router>
        </ThemeProvider>
      </LanguageProvider>
    </Provider>
  );
}

export default App;
