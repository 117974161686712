import { SVGProps } from "react";

export function ReloadIcon({
  width = 24,
  height = 24,
  viewBox = "0 0 24 24",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M23 3.99959V9.99959M23 9.99959H17M23 9.99959L18.36 5.63959C17.2853 4.5643 15.9556 3.77879 14.4952 3.35636C13.0348 2.93393 11.4911 2.88834 10.0083 3.22385C8.52547 3.55935 7.1518 4.26502 6.01547 5.27501C4.87913 6.28499 4.01717 7.56637 3.51 8.99959M1 19.9996V13.9996M1 13.9996H7M1 13.9996L5.64 18.3596C6.71475 19.4349 8.04437 20.2204 9.50481 20.6428C10.9652 21.0652 12.5089 21.1108 13.9917 20.7753C15.4745 20.4398 16.8482 19.7342 17.9845 18.7242C19.1209 17.7142 19.9828 16.4328 20.49 14.9996"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SmReloadIcon({
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      viewBox={"0 0 18 18"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M0.75 2.99993V7.49993M0.75 7.49993H5.25M0.75 7.49993L4.23 4.22993C5.03606 3.42347 6.03328 2.83434 7.12861 2.51751C8.22393 2.20069 9.38167 2.1665 10.4938 2.41813C11.6059 2.66976 12.6361 3.19901 13.4884 3.9565C14.3407 4.71399 14.9871 5.67502 15.3675 6.74993M17.25 14.9999V10.4999M17.25 10.4999H12.75M17.25 10.4999L13.77 13.7699C12.9639 14.5764 11.9667 15.1655 10.8714 15.4824C9.77607 15.7992 8.61833 15.8334 7.50621 15.5817C6.3941 15.3301 5.36385 14.8009 4.5116 14.0434C3.65935 13.2859 3.01288 12.3248 2.6325 11.2499"
        stroke="#065986"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
