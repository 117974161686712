import http from "./http";

const ENDPOINT = "/api/method/freshdi.api.web.v3.chat";

export const apiGetTwlkAccessToken = () =>
  http.get(`${ENDPOINT}.get_farm_access_token`);

export const apiGetCustomerIdentifyProfile = (identity?: string) =>
  http.get(`${ENDPOINT}.get_identity_profile`, { params: { identity } });

export const apiGetIdentity = (identity: string) =>
  http.get(`${ENDPOINT}.get_identity`, { params: { identity } });

// export const apiGetFarmConversations = (limit: number, offset: number) =>
export const apiGetFarmConversations = () =>
  http.get(`${ENDPOINT}.get_farm_conversations`);

export const apiUnlockConversation = (conversation_id: string) =>
  http.post(`${ENDPOINT}.unlock_chat`, { conversation_id });
