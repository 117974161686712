import { SVGProps } from "react";

export function InfoIcon({
  width = 15,
  height = 16,
  viewBox = "0 0 15 16",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M7.66667 14.6673C11.3486 14.6673 14.3333 11.6826 14.3333 8.00065C14.3333 4.31875 11.3486 1.33398 7.66667 1.33398C3.98477 1.33398 1 4.31875 1 8.00065C1 11.6826 3.98477 14.6673 7.66667 14.6673Z"
        fill={color}
      />
      <path
        d="M8.33333 5.33398C8.33333 4.96579 8.03486 4.66732 7.66667 4.66732C7.29848 4.66732 7 4.96579 7 5.33398H8.33333ZM7 8.00065C7 8.36884 7.29848 8.66732 7.66667 8.66732C8.03486 8.66732 8.33333 8.36884 8.33333 8.00065H7ZM7.66667 10.0007C7.29848 10.0007 7 10.2991 7 10.6673C7 11.0355 7.29848 11.334 7.66667 11.334V10.0007ZM7.67333 11.334C8.04152 11.334 8.34 11.0355 8.34 10.6673C8.34 10.2991 8.04152 10.0007 7.67333 10.0007V11.334ZM13.6667 8.00065C13.6667 11.3144 10.9804 14.0007 7.66667 14.0007V15.334C11.7168 15.334 15 12.0507 15 8.00065H13.6667ZM7.66667 14.0007C4.35296 14.0007 1.66667 11.3144 1.66667 8.00065H0.333333C0.333333 12.0507 3.61658 15.334 7.66667 15.334V14.0007ZM1.66667 8.00065C1.66667 4.68694 4.35296 2.00065 7.66667 2.00065V0.667318C3.61658 0.667318 0.333333 3.95056 0.333333 8.00065H1.66667ZM7.66667 2.00065C10.9804 2.00065 13.6667 4.68694 13.6667 8.00065H15C15 3.95056 11.7168 0.667318 7.66667 0.667318V2.00065ZM7 5.33398V8.00065H8.33333V5.33398H7ZM7.66667 11.334H7.67333V10.0007H7.66667V11.334Z"
        fill="#FCFCFD"
      />
    </svg>
  );
}

export function RoundInfoIcon({
  width = 17,
  height = 16,
  viewBox = "0 0 17 16",
  stroke = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M8.49967 5.3335V8.00016M8.49967 10.6668H8.50634M15.1663 8.00016C15.1663 11.6821 12.1816 14.6668 8.49967 14.6668C4.81778 14.6668 1.83301 11.6821 1.83301 8.00016C1.83301 4.31826 4.81778 1.3335 8.49967 1.3335C12.1816 1.3335 15.1663 4.31826 15.1663 8.00016Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export function FillInfoIcon({
  viewBox = "0 0 17 16",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={17}
      height={16}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.5 15C12.366 15 15.5 11.866 15.5 8C15.5 4.13401 12.366 1 8.5 1C4.63401 1 1.5 4.13401 1.5 8C1.5 11.866 4.63401 15 8.5 15Z"
        fill="#475467"
      />
      <path
        d="M8.5 5.2V8M8.5 10.8H8.507M15.5 8C15.5 11.866 12.366 15 8.5 15C4.63401 15 1.5 11.866 1.5 8C1.5 4.13401 4.63401 1 8.5 1C12.366 1 15.5 4.13401 15.5 8Z"
        stroke="#FCFCFD"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
