import { Skeleton } from "@mui/material";
import { LoadingSkeleton } from "components";
import { ComponentType, Suspense } from "react";

export const withLoading = <P extends object>(
  WrappedComponent: ComponentType<P>, // Infer props from the wrapped component
  type: number = 0
) => {
  const Loading: React.FC = () => {
    if (type === 1) {
      return <Skeleton variant="rounded" height={48} />;
    }
    if (type === 2) {
      return <LoadingSkeleton p={2} />;
    }
    return <div />;
  };

  const WithLoadingComponent: React.FC<P> = (props) => {
    return (
      <Suspense fallback={<Loading />}>
        <WrappedComponent {...props} />
      </Suspense>
    );
  };

  return WithLoadingComponent;
};
