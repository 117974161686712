import { SVGProps } from "react";

export function LinkIcon({
  width = 20,
  height = 20,
  viewBox = "0 0 20 20",
  stroke = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg {...{ width, height, viewBox }} fill="none" xmlns={xmlns} {...props}>
      <g clipPath="url(#clip0_12934_112282)">
        <path
          d="M8.33326 10.834C8.69113 11.3124 9.14772 11.7083 9.67205 11.9947C10.1964 12.2812 10.7762 12.4516 11.3721 12.4942C11.9681 12.5369 12.5662 12.4509 13.126 12.2421C13.6858 12.0333 14.1942 11.7065 14.6166 11.284L17.1166 8.78396C17.8756 7.99811 18.2956 6.9456 18.2861 5.85312C18.2766 4.76063 17.8384 3.71558 17.0658 2.94304C16.2933 2.17051 15.2482 1.73231 14.1558 1.72281C13.0633 1.71332 12.0108 2.1333 11.2249 2.89229L9.79159 4.31729M11.6666 9.16729C11.3087 8.68885 10.8521 8.29297 10.3278 8.00651C9.80347 7.72004 9.22367 7.54969 8.62771 7.50701C8.03176 7.46433 7.4336 7.55032 6.8738 7.75914C6.314 7.96796 5.80566 8.29473 5.38326 8.71729L2.88326 11.2173C2.12426 12.0031 1.70429 13.0556 1.71378 14.1481C1.72327 15.2406 2.16148 16.2857 2.93401 17.0582C3.70655 17.8307 4.7516 18.2689 5.84408 18.2784C6.93657 18.2879 7.98908 17.8679 8.77492 17.109L10.1999 15.684"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12934_112282">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SmLinkIcon({
  stroke = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={15}
      viewBox={"0 0 15 15"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <g clipPath="url(#clip0_17619_7403)">
        <path
          d="M6.24988 8.11802C6.51829 8.47685 6.86073 8.77376 7.25397 8.98861C7.64722 9.20346 8.08207 9.33122 8.52904 9.36323C8.976 9.39524 9.42462 9.33075 9.84447 9.17413C10.2643 9.01752 10.6456 8.77244 10.9624 8.45552L12.8374 6.58052C13.4066 5.99114 13.7216 5.20176 13.7145 4.38239C13.7074 3.56303 13.3787 2.77924 12.7993 2.19984C12.2199 1.62044 11.4361 1.29178 10.6168 1.28466C9.79739 1.27754 9.00801 1.59253 8.41863 2.16177L7.34363 3.23052M8.74988 6.86802C8.48147 6.50919 8.13903 6.21228 7.74579 5.99743C7.35254 5.78258 6.91769 5.65482 6.47072 5.62281C6.02376 5.5908 5.57514 5.65529 5.15529 5.81191C4.73544 5.96852 4.35418 6.2136 4.03738 6.53052L2.16238 8.40552C1.59314 8.9949 1.27815 9.78428 1.28527 10.6036C1.29239 11.423 1.62105 12.2068 2.20045 12.7862C2.77985 13.3656 3.56364 13.6943 4.383 13.7014C5.20237 13.7085 5.99175 13.3935 6.58113 12.8243L7.64988 11.7555"
          stroke="#667085"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17619_7403">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(0 -0.00695801)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
