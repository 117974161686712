import { styled } from "@mui/material";
import { LinkProps, Link as LinkLib } from "react-router-dom";

const Link = (props: LinkProps) => {
  return <StyledLink {...props} />;
};

const StyledLink = styled(LinkLib)({
  textDecoration: "none",
  color: "initial",
});

export default Link;
