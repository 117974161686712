import { SVGProps } from "react";

export function CompanyIcon({
  stroke = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox={"0 0 20 20"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M18.334 17.4999H1.66732"
        stroke="#667085"
        strokeWidth="1.38889"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.167 10.8333H15.8337C16.2939 10.8333 16.667 11.2064 16.667 11.6666V16.6666C16.667 17.1269 16.2939 17.5 15.8337 17.5H14.167C13.7068 17.5 13.3337 17.1269 13.3337 16.6666V11.6666C13.3337 11.2064 13.7068 10.8333 14.167 10.8333Z"
        stroke="#667085"
        strokeWidth="1.38889"
        strokeLinejoin="round"
      />
      <path
        d="M15.001 14.1666H14.5843"
        stroke="#667085"
        strokeWidth="1.38889"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M4.16732 1.66663H12.5007C12.9609 1.66663 13.334 2.03972 13.334 2.49996V16.6666C13.334 17.1269 12.9609 17.5 12.5007 17.5H4.16732C3.70708 17.5 3.33398 17.1269 3.33398 16.6666V2.49996C3.33398 2.03972 3.70708 1.66663 4.16732 1.66663Z"
        stroke="#667085"
        strokeWidth="1.38889"
        strokeLinejoin="round"
      />
      <path
        d="M10.834 4.16663H9.16732V5.83329H10.834V4.16663ZM7.50065 4.16663H5.83398V5.83329H7.50065V4.16663ZM10.834 7.08329H9.16732V8.74996H10.834V7.08329ZM7.50065 7.08329H5.83398V8.74996H7.50065V7.08329ZM7.50065 9.99996H5.83398V11.6666H7.50065V9.99996ZM7.50065 12.9166H5.83398V14.5833H7.50065V12.9166Z"
        fill="#667085"
      />
    </svg>
  );
}
