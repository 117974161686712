import { Dialog } from "@mui/material";
import { UserPlusIcon } from "assets/icons";
import TextButton from "./TextButton";
import ContactUnlockPopup from "components/popup/ContactUnlockPopup";
import { useTranslation } from "hooks/useTranslation";
import { useState } from "react";
import { ChatUserDetailType } from "types/chat";

type Props = {
  userDetail?: ChatUserDetailType;
  onReload?: () => void;
};

const UnlockChatButton = ({ userDetail, onReload }: Props) => {
  const t = useTranslation();
  const [showDialog, setShowDialog] = useState(false);

  const onCloseDialog = () => {
    setShowDialog(false);
  };

  const onOpenDialog = () => setShowDialog(true);

  return (
    <>
      <TextButton
        onClick={onOpenDialog}
        sx={{
          flexDirection: "column",
          fontSize: 12,
          whiteSpace: "nowrap",
        }}
      >
        <UserPlusIcon />
        {t("unlock_chat")}
      </TextButton>

      <Dialog
        open={showDialog}
        onClose={onCloseDialog}
        PaperProps={{ sx: { width: "100%", maxWidth: 473 } }}
      >
        <ContactUnlockPopup
          {...{ onReload }}
          onClose={onCloseDialog}
          contact_id={userDetail?.name}
          title="use_credits_unlock_chat_card"
        />
      </Dialog>
    </>
  );
};

export default UnlockChatButton;
