import { SVGProps } from "react";

export function VerifyIcon({
  width = 57,
  height = 19,
  viewBox = "0 0 57 19",
  fill = "none",
  stroke = "#ADB3BC",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns={xmlns}
      {...props}
    >
      <g clipPath="url(#clip0_1524_22775)">
        <path
          d="M32.9667 11.4995L32.7203 13.0564H35.3748L35.0504 15.1046H32.3967L31.8178 18.7587H29.3982L30.8736 9.45068H36.2186L35.8922 11.4995H32.9667Z"
          fill="#008934"
        />
        <path
          d="M38.5626 18.7587H36.1433L37.6175 9.45068H40.0372L38.5626 18.7587Z"
          fill="#008934"
        />
        <path
          d="M43.9323 11.4995L43.6856 13.0564H46.4141L46.0905 15.1046H43.3617L43.1067 16.7087H45.9837L45.6596 18.7587H40.3623L41.8377 9.45068H47.1326L46.8088 11.4995H43.9323Z"
          fill="#008934"
        />
        <path
          d="M50.6155 11.4995H51.1834C52.7383 11.4995 53.6889 12.4383 53.4237 14.1048C53.1384 15.9179 51.7779 16.7087 50.3339 16.7087H49.791L50.6155 11.4995ZM47.0466 18.7587H50.479C53.0575 18.7587 55.5275 16.7225 55.9432 14.1048C56.3581 11.4883 54.5441 9.45068 51.9515 9.45068H48.5206L47.0466 18.7587Z"
          fill="#008934"
        />
        <path
          d="M13.7539 11.4995L13.5081 13.0564H16.2357L15.9125 15.1046H13.1839L12.9292 16.7087H15.8071L15.4812 18.7587H10.1848L11.6596 9.45068H16.9557L16.6316 11.4995H13.7539Z"
          fill="#008934"
        />
        <path
          d="M20.4805 11.2972H20.7154C21.5057 11.2972 22.3716 11.4452 22.211 12.4574C22.0515 13.4701 21.1383 13.6178 20.3487 13.6178H20.1135L20.4805 11.2972ZM22.2991 14.9389C23.6579 14.7043 24.55 13.5931 24.7524 12.31C25.067 10.3225 23.8107 9.44531 21.9718 9.44531H18.3537L16.8806 18.7536H19.3017L19.8671 15.1738H19.8918L21.6084 18.7536H24.6211L22.2991 14.9389Z"
          fill="#008934"
        />
        <path
          d="M27.6061 18.7587H25.1851L26.6601 9.45068H29.0798L27.6061 18.7587Z"
          fill="#008934"
        />
        <path
          d="M14.3851 0.169189L10.5765 6.23163L5.39838 15.0001L5.09553 11.7316L4.85803 8.17827H0.101987L0.0541992 8.45866C1.27509 8.45866 1.99738 9.07299 2.19832 10.3307L3.12241 18.6159L3.12817 18.5975L3.14055 18.7189H6.41056L12.2545 6.89836L15.798 0.169189H14.3851Z"
          fill="#008934"
        />
        <path
          d="M6.21554 10.1555H6.49133L13.7081 0.201416L2.10693 4.05446V4.33284L5.87843 5.93805L6.21554 10.1555Z"
          fill="#FD853A"
        />
      </g>
      <defs>
        <clipPath id="clip0_1524_22775">
          <rect width="56.1364" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SmVerifyIcon({
  stroke = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={15}
      viewBox={"0 0 15 15"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <g clipPath="url(#clip0_17619_7392)">
        <path
          d="M13.75 6.91808V7.49308C13.7492 8.84084 13.3128 10.1523 12.5058 11.2317C11.6989 12.3112 10.5646 13.1009 9.2721 13.483C7.97964 13.8652 6.59829 13.8193 5.33404 13.3522C4.0698 12.8851 2.99041 12.0219 2.25685 10.8912C1.52329 9.7606 1.17487 8.42312 1.26355 7.07828C1.35223 5.73343 1.87325 4.45328 2.74892 3.42875C3.6246 2.40422 4.80799 1.69019 6.12262 1.39317C7.43725 1.09615 8.81267 1.23204 10.0438 1.78058M13.75 2.49308L7.5 8.74933L5.625 6.87433"
          stroke={stroke}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17619_7392">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(0 -0.00695801)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function RoundedVerifyIcon({
  stroke = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      viewBox={"0 0 18 18"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M6.64891 15.375H4.61172C4.18891 15.375 3.82708 15.2246 3.52624 14.9237C3.22541 14.6229 3.07473 14.2608 3.07422 13.8375V11.8003L1.59438 10.3012C1.45344 10.1475 1.34453 9.97784 1.26766 9.79232C1.19078 9.60679 1.15234 9.41768 1.15234 9.22498C1.15234 9.03228 1.19078 8.84342 1.26766 8.65841C1.34453 8.4734 1.45344 8.30351 1.59438 8.14873L3.07422 6.64967V4.61248C3.07422 4.18967 3.22489 3.82784 3.52624 3.52701C3.82759 3.22617 4.18942 3.07549 4.61172 3.07498H6.64891L8.14797 1.59514C8.30172 1.4542 8.47161 1.34529 8.65765 1.26842C8.84369 1.19154 9.03254 1.15311 9.22422 1.15311C9.41589 1.15311 9.60501 1.19154 9.79156 1.26842C9.97811 1.34529 10.1477 1.4542 10.3005 1.59514L11.7995 3.07498H13.8367C14.2595 3.07498 14.6216 3.22566 14.923 3.52701C15.2243 3.82836 15.3747 4.19018 15.3742 4.61248V6.64967L16.8541 8.14873C16.995 8.30248 17.1039 8.47237 17.1808 8.65841C17.2577 8.84445 17.2961 9.03331 17.2961 9.22498C17.2961 9.41666 17.2577 9.60577 17.1808 9.79232C17.1039 9.97887 16.995 10.1485 16.8541 10.3012L15.3742 11.8003V13.8375C15.3742 14.2603 15.2238 14.6224 14.923 14.9237C14.6221 15.2251 14.26 15.3755 13.8367 15.375H11.7995L10.3005 16.8548C10.1467 16.9958 9.97708 17.1047 9.79156 17.1815C9.60603 17.2584 9.41692 17.2969 9.22422 17.2969C9.03152 17.2969 8.84266 17.2584 8.65765 17.1815C8.47264 17.1047 8.30274 16.9958 8.14797 16.8548L6.64891 15.375Z"
        fill="#008934"
      />
      <path
        d="M11.7083 5.24994L10.8056 6.65473L8.23499 10.781L8.06584 9.05042L7.93318 7.16911H5.27669L5.25 7.31756C5.93193 7.31756 6.33536 7.64283 6.4476 8.30875L6.96375 12.6954L6.96697 12.6856L6.97388 12.7499H8.80035L12.0645 6.49145L12.75 5.24994H11.7083Z"
        fill="white"
      />
    </svg>
  );
}

export function GrayRoundedVerifyIcon({
  stroke = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={19}
      height={18}
      viewBox={"0 0 19 18"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M7.14891 15.375H5.11172C4.68891 15.375 4.32708 15.2246 4.02624 14.9237C3.72541 14.6229 3.57473 14.2608 3.57422 13.8375V11.8003L2.09438 10.3012C1.95344 10.1475 1.84453 9.97784 1.76766 9.79232C1.69078 9.60679 1.65234 9.41768 1.65234 9.22498C1.65234 9.03228 1.69078 8.84342 1.76766 8.65841C1.84453 8.4734 1.95344 8.30351 2.09438 8.14873L3.57422 6.64967V4.61248C3.57422 4.18967 3.72489 3.82784 4.02624 3.52701C4.32759 3.22617 4.68942 3.07549 5.11172 3.07498H7.14891L8.64797 1.59514C8.80172 1.4542 8.97161 1.34529 9.15765 1.26842C9.34369 1.19154 9.53254 1.15311 9.72422 1.15311C9.91589 1.15311 10.105 1.19154 10.2916 1.26842C10.4781 1.34529 10.6477 1.4542 10.8005 1.59514L12.2995 3.07498H14.3367C14.7595 3.07498 15.1216 3.22566 15.423 3.52701C15.7243 3.82836 15.8747 4.19018 15.8742 4.61248V6.64967L17.3541 8.14873C17.495 8.30248 17.6039 8.47237 17.6808 8.65841C17.7577 8.84445 17.7961 9.03331 17.7961 9.22498C17.7961 9.41666 17.7577 9.60577 17.6808 9.79232C17.6039 9.97887 17.495 10.1485 17.3541 10.3012L15.8742 11.8003V13.8375C15.8742 14.2603 15.7238 14.6224 15.423 14.9237C15.1221 15.2251 14.76 15.3755 14.3367 15.375H12.2995L10.8005 16.8548C10.6467 16.9958 10.4771 17.1047 10.2916 17.1815C10.106 17.2584 9.91692 17.2969 9.72422 17.2969C9.53152 17.2969 9.34266 17.2584 9.15765 17.1815C8.97264 17.1047 8.80274 16.9958 8.64797 16.8548L7.14891 15.375Z"
        fill="#D0D5DD"
      />
      <path
        d="M12.2083 5.24994L11.3056 6.65473L8.73499 10.781L8.56584 9.05042L8.43318 7.16911H5.77669L5.75 7.31756C6.43193 7.31756 6.83536 7.64283 6.9476 8.30875L7.46375 12.6954L7.46697 12.6856L7.47388 12.7499H9.30035L12.5645 6.49145L13.25 5.24994H12.2083Z"
        fill="white"
      />
    </svg>
  );
}
