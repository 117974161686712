import { BoxProps, Divider, IconButton, Stack } from "@mui/material";
import {
  Attachment,
  Img,
  Row,
  SmTextMd,
  StyledA,
  TimeDiff,
  XsText,
  XsTextMd,
  XXsText,
} from "components";
import { DIRECT_PATHS } from "constants/routes";
import dayjs from "dayjs";
import { Fragment, memo, useMemo, useState } from "react";
import { RFQType } from "types/rfq";
import CountryFlag from "../CountryFlag";
import { CrownTooltip, VerifyRFQTooltip } from "../Tooltip";
import useUser from "hooks/useUser";
import { followRel } from "helpers/format";
import { QuotationType } from "types/quotaion";
import RFQReviewButton from "../button/RFQReviewButton";
import { APP_ICONS, DubbleCheckIcon } from "assets/icons";
import ListingChip from "../chips/ListingChip";
import { COLORS } from "constants/layout";
import StyledRFQ from "./RFQStyles";
import useBreakpoints from "hooks/useBreakpoint";
import { useNotify } from "providers/notify";
import BuyerInfors from "components/infos/BuyerInfors";
import { useTranslation } from "hooks/useTranslation";
import { ShareIcon } from "assets/icons/share.icon";

type Props = {
  rfq: RFQType;
  reviewQuote?: QuotationType;
  hideButton?: boolean;
  fullMode?: boolean;
  sx?: BoxProps["sx"];
  source?: string;
  fullLink?: boolean;
};

const RFQ = ({
  rfq,
  sx,
  reviewQuote,
  hideButton,
  fullMode = false,
  source,
  fullLink,
}: Props) => {
  const t = useTranslation();
  const {
    name,
    product_name,
    quantity,
    unit,
    posted_at,
    payment_term,
    destination_port,
    attachment = [],
    is_featured,
    fd_country,
    country_code,
    trade_term,
    is_verified,
    is_seen,
    quotation_id,
    no_follow = 0,
    details,
    categories = [],
    buyer,
    num_posted_rfq = 0,
    activity_tags = [],
    refer_verified_supplier,
    quotation_received,
    quoted_at,
  } = rfq;
  const NOW = dayjs();
  const { isLoggedIn } = useUser();
  const { isMobile } = useBreakpoints();
  const { setNotify } = useNotify();
  const [isSeen] = useState(is_seen === 1);

  const infors = useMemo(
    () =>
      [
        {
          label: "quantity",
          value: (quantity || "") + " " + (unit?.label || ""),
          hide: !quantity,
        },
        { label: "payment_terms", value: payment_term },
        { label: "trade_terms", value: trade_term },
        {
          label: "destination_port",
          value: destination_port,
        },
      ].filter((info) => !info.hide && info.value),
    [rfq]
  );

  const onClickCopyContent = () => {
    navigator.clipboard.writeText(DIRECT_PATHS.rfqDetail(name));
    setNotify({
      open: true,
      type: "success",
      msg: t("copy_successful"),
    });
  };

  const isShorterMode = useMemo(
    () => isMobile || !fullMode,
    [isMobile, fullMode]
  );

  return (
    <StyledRFQ
      direction="row"
      spacing={2}
      className={"rfq " + (isSeen && isLoggedIn ? "is-seen" : "")}
      sx={sx}
    >
      <Stack
        spacing={1}
        flex={3}
        className="rfq-detail"
        maxWidth={isShorterMode ? "100%" : "calc(75% - 16px)"}
      >
        <Stack spacing={0.5}>
          <Row spacing={1} justifyContent="space-between">
            <XsText color={COLORS.gray[500]} className="time-text">
              <TimeDiff startTime={posted_at} endTime={NOW.format()} />
            </XsText>
            <Row spacing={1}>
              {is_featured === 1 && (
                <CrownTooltip
                  title={t("this_is_a_premium_sourcing_request")}
                  isFull
                />
              )}
              {is_verified === 1 && <VerifyRFQTooltip isVerified />}
            </Row>
            {isSeen && isLoggedIn ? (
              <Row spacing={0.5}>
                <DubbleCheckIcon height={6} />
                <XXsText color={COLORS.gray[500]}>{t("seen")}</XXsText>

                {/* Quote text */}
                <AlreadyQuotedText date={quoted_at} />
              </Row>
            ) : (
              <div style={{ width: 48 }} />
            )}
          </Row>
          <Divider />
        </Stack>

        <Row spacing={1} justifyContent="space-between">
          <StyledA
            href={DIRECT_PATHS.rfqDetail(name)}
            rel={followRel(no_follow)}
            className="title"
          >
            <SmTextMd
              className="ellipsis-text rfq-title"
              component="h2"
              color={COLORS.green[600]}
            >
              {product_name}
            </SmTextMd>
          </StyledA>
          <Stack direction="row" spacing={1} className="action-icons">
            <IconButton className="fit" onClick={onClickCopyContent}>
              <Img src={APP_ICONS.share} />
            </IconButton>
            {/* <Img src={APP_ICONS.bookmark} /> */}
          </Stack>
        </Row>

        {details && (
          <XsText color="#27272A" className="cutoff-text twoline">
            {details}
          </XsText>
        )}

        <Stack direction="row" flexWrap="wrap" gap={1}>
          {infors.map(({ value, label }, i) => (
            <XsText className="info-text ellipsis-text" key={i}>
              {t(label)}: <span>{value}</span>
            </XsText>
          ))}
        </Stack>

        {/* RFQ attachment */}
        {attachment.length > 0 && <Attachment attachment={attachment[0].url} />}

        {/* Buyer country */}
        {fd_country && (
          <Row spacing={0.5} component="h4">
            <XsText>{t("buyer_from")}</XsText>
            <CountryFlag country={fd_country} {...{ country_code }} />
          </Row>
        )}

        {(categories?.length > 0 || quotation_received) && (
          <Row
            justifyContent="space-between"
            direction={{ xs: "column", md: "row" }}
            spacing={1}
            alignItems={{ xs: "flex-start", md: "center" }}
          >
            {/* Buyer category */}
            {categories?.length > 0 ? (
              <Stack direction="row" flexWrap="wrap" gap={1} component="h3">
                <XsText color={COLORS.gray[500]}>{t("buyer_of")}</XsText>
                {categories?.map(({ name, category_name }, i) => (
                  <Fragment key={i}>
                    <StyledA
                      onClick={(e) => e.stopPropagation()}
                      href={DIRECT_PATHS.categoryRfq(name, true)}
                    >
                      <XsText color={COLORS.green[600]}>{category_name}</XsText>
                    </StyledA>
                    {i < categories.length - 1 && (
                      <Divider flexItem orientation="vertical" />
                    )}
                  </Fragment>
                ))}
              </Stack>
            ) : (
              <div />
            )}
            {/* Received quotation */}
            {quotation_received && (
              <XsText color={COLORS.green[600]}>
                {t("received_value_quotations", { value: quotation_received })}
              </XsText>
            )}
          </Row>
        )}

        {/* Refer text */}
        {refer_verified_supplier === 1 && (
          <XsText color={COLORS.warning[900]}>
            {t("buyer_want_receive_from_premium_supplier")}
          </XsText>
        )}

        {/* Activities tags */}
        {isMobile && <ListingChip tags={activity_tags} maxHeight="auto" />}

        {/* Review RFQ button  */}
        <Stack spacing={0.5}>
          {!hideButton && reviewQuote && (
            <RFQReviewButton
              {...{ reviewQuote, source }}
              rfqId={name}
              quotationId={quotation_id}
            />
          )}
        </Stack>
      </Stack>

      {/* Buyer section: show on desktop only */}
      {!isShorterMode && (
        <Stack flex={1} spacing={1}>
          <BuyerInfors company={buyer} fullLink />

          {num_posted_rfq > 0 && (
            <XsTextMd className="status-label orange-label">
              {t("qlt_rfqs_posted", { quantity: num_posted_rfq })}
            </XsTextMd>
          )}

          <ListingChip tags={buyer?.activity_tags} maxHeight="auto" />
        </Stack>
      )}
    </StyledRFQ>
  );
};

const AlreadyQuotedText = memo(({ date }: { date?: string }) => {
  const t = useTranslation();
  const { isMobile } = useBreakpoints();

  if (!date) return null;

  return (
    <XXsText
      color={COLORS.green[600]}
      display="flex"
      alignItems="center"
      gap={0.5}
    >
      <ShareIcon />
      {t(isMobile ? "quoted" : "quoted_on_date", {
        date: dayjs(date).format("D MMM YYYY"),
      })}
    </XXsText>
  );
});

export default memo(RFQ);
