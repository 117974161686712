import { DIRECT_PATHS, PATHS } from "constants/routes";
import useUser from "hooks/useUser";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import Row from "./Row";
import { CloseIcon, UnLockIcon } from "assets/icons";
import { OpenTabLink, SmTextMd, TextMd } from "./Text";
import { BaseButton, YellowButton } from "./Button";
import { apiPurchaseInquiry } from "services/api/contact";
import { Stack, styled } from "@mui/material";
import { memo } from "react";
import GetContactButton from "./button/GetContactButton";

const InquiryUnlockPopup = (props: {
  onReload?: () => void;
  onClose?: () => void;
  inquiry_id: string;
}) => {
  const t = useIntl();
  const { inquiry_id, onClose, onReload } = props;
  const navigate = useNavigate();
  const { userContactQuote } = useUser();

  const onDirectToInquiryDetail = () => {
    navigate(DIRECT_PATHS.inquiryDetail(inquiry_id));
    onReload?.();
  };

  return (
    <StyledUnlockPoup spacing={2}>
      <Row spacing={1}>
        <UnLockIcon width={24} className="lock-icon" />
        <TextMd>{t.formatMessage({ id: "unlock_inquiry_title" })}</TextMd>
        <div className="tmp" />
      </Row>
      <CloseIcon className="close-icon" onClick={onClose} />

      {userContactQuote === 0 && (
        <SmTextMd textAlign="center">
          {t.formatMessage({ id: "running_out_on_credit" })}
        </SmTextMd>
      )}

      <Row direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 2 }}>
        <BaseButton onClick={onClose} className="back-btn">
          {t.formatMessage({ id: "go_back" })}
        </BaseButton>
        {userContactQuote > 0 && (
          <GetContactButton
            className="supp-price-btn"
            apiPurchase={apiPurchaseInquiry}
            contactData={{ inquiry_id }}
            onBonusGetContact={onDirectToInquiryDetail}
          />
        )}
        {userContactQuote === 0 && (
          <OpenTabLink href={PATHS.supplierPricing} className="supp-price-btn">
            <YellowButton>
              {t.formatMessage({ id: "become_premium_membership" })}
            </YellowButton>
          </OpenTabLink>
        )}
      </Row>
    </StyledUnlockPoup>
  );
};

export const StyledUnlockPoup = styled(Stack)(({ theme }) => ({
  padding: 16,
  maxWidth: 447,
  position: "relative",
  ".lock-icon": {
    minWidth: 24,
  },
  ".close-icon": {
    width: 20,
    position: "absolute",
    top: 0,
    right: 12,
    cursor: "pointer",
  },
  ".tmp": {
    width: 12,
  },
  ".supp-price-btn": {
    flex: 1,
  },
  [theme.breakpoints.down("md")]: {
    ".back-btn, .supp-price-btn": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
}));

export default memo(InquiryUnlockPopup);
